.projects-section {
  min-height: 100vh;
  padding-bottom: 150px;
  background: #0c1e2f;
}

.transition-background {
  background: linear-gradient(black, #0c1e2f);
}




