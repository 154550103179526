.contact-title-container {
  background: linear-gradient(#0c1e2f, black);
}

.bottom {
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
}

.contact-links {
  position: absolute;
  height: 100%;
  right: 25%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.triangle-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-triangle {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: relative;
  height: 100%;
  width: 50%;
  background: rgba(0, 0, 0,0.8);
  z-index: -1;
}

.contact-img {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -4;
  object-fit: cover;
  object-position: center;
}

.contact-links li {
  margin: 10px;
}

.contact-links a {
  font-size: 1.4rem;
  padding: 2px;
  border-bottom: 2px solid;
}

.contact-links a:hover {
  color: #A247B0;
}

@media (max-width: 991px) {
  .bottom {
    height: 350px;
  }

  .center-triangle {
    width: 55%;
  }
}

@media (max-width: 767px) {
  .bottom {
    height: 300px;
  }

  .center-triangle {
    width: 60%;
  }
}

@media (max-width: 575px) {
  .bottom {
    height: 250px;
  }

  .center-triangle {
    width: 68%;
  }
}

@media (max-width: 400px) {
  .bottom {
    height: 200px;
  }

  .center-triangle {
    width: 100%;
  }
}