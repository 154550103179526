.about-section {
  padding-top: 10px;
  min-height: 100vh;
  padding-bottom: 120px;
  background-image: url("../../images/nightskyCompressed.jpg");
  background-size: cover;
}

.parallax-img {
  background-attachment: fixed;
}

.skills-container {
  margin-top: 75px;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.3);
  padding-top: 10px;
  padding-bottom: 10px;
}

.bio {
  font-size: 1.5rem;
  padding: 10px;
}

.bio a {
  text-decoration: none;
}

.bio a:hover {
  cursor: pointer;
  color: #72cdda;
  border-bottom: 2px solid;
}

.skills {
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 8px;
}