.hero-section {
  min-height: 1200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0.25turn, rgb(70, 70, 70), grey, rgb(70, 70, 70));
  overflow: hidden;
}

.triangle {
  position: absolute;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangle-down {
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  position: absolute;
}

.t-img {
  position: absolute;
  object-fit: cover;
  height: 1000px;
  width: 700px;
}

.name {
  position: absolute;
  color: black;
  left: calc(47% - 390px);
  top: 280px;
  font-size: 2.5rem;
  font-family: 'Expletus Sans', cursive;
  font-weight: bold;
}

.title {
  position: absolute;
  color: black;
  left: calc(47% - 390px);
  top: 340px;
  font-size: 2.5rem;
  font-family: 'Expletus Sans', cursive;
  font-weight: bold;
}

.quote-container {
  position: absolute;
  width: 280px;
  top: 745px;
  left: 47%;
  font-size: 1.4rem;
  font-style: italic;
}

.t1 {
  left: calc(47% - 182px);
  top: 106px;
  height: 100px;
  width: 120px;
}

.t1-img {
  left: -118px;
  top: -68px;
  height: 250px;
  width: 350px;
}

.t2 {
  left: calc(47% - 115px);
  top: 90px;
  height: 200px;
  width: 240px;
}

.t2-img {
  left: -185px;
  top: -52px;
  height: 333px;
  width: 700px;
}

.t3 {
  height: 250px;
  width: 300px;
  top: 78px;
  left: 47%;
}

.t3-img {
  left: -300px;
  top: -40px;
  height: 333px;
  width: 700px;
}

.t4 {
  height: 110px;
  width: 132px;
  left: calc(47% + 200px);
  top: 135px;
}

.t4-img {
  left: -150px;
  top: -97px;
  height: 250px;
  width: 350px;
}

.t5 {
  height: 75px;
  width: 90px;
  left: calc(47% + 290px);
  top: 150px;
}

.t5-img {
  left: -590px;
  top: -112px;
  height: 333px;
  width: 700px;
}

.t6 {
  height: 250px;
  width: 300px;
  left: calc(47%);
  top: 338px;
}

.t6-img {
  left: -300px;
  top: -300px;
}


.t7 {
  height: 140px;
  width: 168px;
  left: calc(47% - 70px);
  top: 388px;
}

.t7-img {
  left: -230px;
  top: -350px;
  height: 500px;
  width: 700px;
}

.t8 {
  height: 180px;
  width: 216px;
  left: calc(47% - 110px);
  top: 538px;
}

.t8-img {
  left: -190px;
  top: -500px;
}

.t9 {
  height: 160px;
  width: 192px;
  left: calc(47% - 210px);
  top: 548px;
}

.t9-img {
  left: -90px;
  top: -510px;
  height: 1000px;
  width: 350px;
}

.t10 {
  height: 160px;
  width: 198px;
  left: calc(47% - 210px);
  top: 715px;
}

.t10-img {
  left: -90px;
  top: -677px;
  height: 1000px;
  width: 350px;
}

.t11 {
  height: 120px;
  width: 144px;
  left: calc(47% - 210px);
  top: 850px;
}

.t11-img {
  left: -90px;
  top: -812px;
  height: 1000px;
  width: 350px;
}

.t12 {
  height: 160px;
  width: 192px;
  left: calc(47% - 200px);
  top: 978px;
}

.t12-img {
  left: -100px;
  top: -940px;
  height: 1000px;
  width: 350px;
}

@media (max-width: 900px) {
  .name {
    left: calc(47% - 330px);
  }

  .title {
    left: calc(47% - 330px);
  }
}

@media (max-width: 730px) {
  .name {
    left: calc(47% - 140px);
    top: 292px;
    font-size: 2.1rem;
  }
  
  .title {
    left: calc(47% - 140px);
    top: 340px;
    font-size: 2.1rem;
  }
  
  .t1 {
    left: calc(47% - 205px);
    top: 106px;
    height: 100px;
    width: 120px;
  }
  
  .t1-img {
    left: -95px;
    top: -68px;
  }
  
  .t2 {
    left: calc(47% - 144px);
    top: 90px;
    height: 200px;
    width: 240px;
  }
  
  .t2-img {
    left: -156px;
    top: -52px;
  }
  
  .t3 {
    height: 190px;
    width: 228px;
    top: 78px;
    left: 47%;
  }
  
  .t3-img {
    left: -300px;
    top: -40px;
  }
  
  .t4 {
    height: 110px;
    width: 132px;
    left: calc(47% + 160px);
    top: 135px;
  }
  
  .t4-img {
    left: -110px;
    top: -97px;
  }
  
  .t5 {
    height: 75px;
    width: 90px;
    left: calc(47% + 134px);
    top: 305px;
  }
  
  .t5-img {
    left: -434px;
    top: -267px;
  }
  
  .t6 {
    height: 160px;
    width: 192px;
    left: calc(47% + 30px);
    top: 390px;
  }
  
  .t6-img {
    left: -330px;
    top: -352px;
  }
}

@media (max-width: 500px) {
  .name {
    left: calc(47% - 110px);
  }
  
  .title {
    left: calc(47% - 110px);
  }

  .quote-container {
    width: 200px;
    top: 785px;
    left: calc(47% - 35px);
  }
}
