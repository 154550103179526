.project-panel {
  position: relative;
  width: 100%;
  height: 400px;
}

.project-panel h2 {
  font-size: 2.6rem;
}

.project-panel p {
  font-weight: 400;
  font-size: 1rem;
}

.project-panel h3 {
  font-weight: 900;
  font-size: 1.8rem;
}

.project-panel a {
  font-size: 1rem;
  padding-bottom: 3px;
  color: rgb(5, 5, 5);
}

.project-panel a:hover {
  border-bottom: 1px solid rgb(5, 5, 5);
  color: rgb(5, 5, 5);
}

.project-image {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.description-background {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: rgba(80, 80, 80, 0.9);
  color: rgb(235, 235, 235);
}

.top-left-panel {
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
  left: 0;
}

.bottom-left-panel {
  clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 100%);
  left: 0;
}

.top-right-panel {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
  right: 0;
}

.bottom-right-panel {
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
  right: 0;
}

.project-info-wrapper {
  width: 75%;
  height: 100%;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-info {
  margin-left: auto;
}

.project-info {
  max-width: 240px;
}

.project-links-container div {
  margin-top: 6px;
  margin-bottom: 6px;
}

@media (max-width: 991px) {
  .project-panel {
    height: 340px;
  }

  .description-background {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .project-panel {
    height: 280px;
  }

  .project-info {
    max-width: 210px;
  }

  .description-background {
    width: 70%;
  }
}

@media (max-width: 575px) {
  .project-panel {
    height: 240px;
  }
}

@media (max-width: 500px) {
  .project-info {
    max-width: 200px;
  }

  .project-info {
    max-width: 180px;
  }
}

@media (max-width: 400px) {
  .project-panel {
    height: 200px;
  }

  .description-background {
    width: 80%;
  }
}

@media (max-width: 350px) {
  .description-background {
    width: 88%;
  }
}

.project-1 {
  background-image: url('../../images/betterMusic.jpg');
}

.project-2 {
  background-image: url('../../images/parkDetail.jpg');
}

.project-3 {
  background-image: url('../../images/invoiceGenerator.jpg');
}

.project-4 {
  background-image: url('../../images/sneakerheadsFeed.jpg');
}

.project-5 {
  background-image: url('../../images/employeeManagementCompressed2.jpg');
}

.responsive-image {
  background-image: url('../../images/responsiveCompressed.jpg');
}

.dynamic-image {
  background-image: url('../../images/dynamic.jpg');
}

.stylish-image {
  background-image: url('../../images/stylishPicture.jpg');
}

.simple-image {
  background-image: url('../../images/simplicity.jpg');
}
