* {
  box-sizing: border-box;
}

html {
  font-size: 17px;
}
@media (max-width: 900px) {
  html { font-size: 15px; }
}
@media (max-width: 500px) {
  html { font-size: 13px; }
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

hr {
  border: 1px solid #e3fff8;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.off-white {
  color: rgb(235, 235, 235);
}

.light-blue {
  color: #72cdda;
}

.orange {
  color: #F99130;
}

.section-title {
  border-bottom: 2px solid;
  display: inline-block;
}